<template>
  <div ref="main">
    {{ dataChart }}
  </div>
</template>

<script>
import { uuid } from '@avue/avue-data/utils/utils'
import mqtt from 'mqtt';
export default {
  name: "iot",
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      checkTime: null,
      client: {}
    }
  },
  computed: {
    topic () {
      return this.option.topic
    },
    url () {
      return this.option.url
    },
    params () {
      return this.option.params || []
    },
    productKey () {
      return this.option.productKey
    },
    deviceName () {
      return this.option.deviceName
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.client = mqtt.connect(this.url, {
        clientId: uuid(),
        username: this.option.username,
        password: this.option.password,
        connectTimeout: 5000
      },);
      this.client.on('connect', () => {
        this.onSubscribe();
        // 监听消息
        this.onMessage();
        //循环监听
        this.onListen();
      })
    },
    getTopicPrefix () {
      return `${this.topic}/${this.productKey}/${this.deviceName}/`;
    },
    onListen () {
      this.params.forEach(ele => {
        this.checkTime = setInterval(() => {
          if (this.client.connected == true) {
            this.sendProperty({ identifier: ele })
          } else {
            clearInterval(this.checkTime)
          }
        }, 500)
      })
    },
    sendProperty (data) {
      let topic = this.getTopicPrefix() + 'thing/service/property/get';
      const req = {
        id: uuid,
        version: '1.0',
        params: {
          ...data
        }
      };
      const message = JSON.stringify(req);
      this.publish(topic, message);
    },
    publish (topic, message) {
      this.client.publish(topic, message, (err) => {
        if (err) {
          console.log(err)
        }
      });
    },
    onSubscribe () {
      // 设备响应云端获取设备属性
      this.client.subscribe(this.getTopicPrefix() + "thing/service/property/get_reply", function (err) {
        if (!err) {
          console.log('ServicePropertyGetReply 订阅成功');
        } else {
          console.log('ServicePropertyGetReply 订阅失败', err);
        }
      });
    },
    onMessage () {
      // 接收订阅消息
      this.client.on('message', (topic, message) => {
        if (topic === this.getTopicPrefix() + "thing/service/property/get_reply") {
          // 设备响应云端获取设备属性
          const data = JSON.parse(message.toString());
          this.dataChart = data.params
        }
      })
    }
  }
};
</script>
