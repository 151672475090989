<template>
  <el-dialog center
             :show-close="false"
             :close-on-press-escape="false"
             :close-on-click-modal="false"
             append-to-body
             :visible.sync="dialogVisible"
             title="人机识别"
             width="400px">
    <center>
      <span>
        <span style="color:red">扫码下方二维码，回复<b>【可视化】</b></span>
      </span>
      <br />
      <br />
      <img width="200"
           src="https://s21.ax1x.com/2024/09/07/pAel5ad.jpg" />
      <br />
      <br />
      <el-input v-model="value"
                placeholder="请输入大屏验证码"></el-input>
    </center>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary"
                   @click="submit">确 认</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      value: '',
      dialogVisible: false
    }
  },
  computed: {
    isBuild () {
      return this.$route ? this.$route.name === 'build' : false;
    }
  },
  mounted () {
    // this.$notify({
    //   title: '价格月底上浮通知',
    //   message: '感觉对我们的支持和信任，由于大屏升级即将接近尾声，考虑到研发成本，计划月底价格上浮，同时 AI 功能已上线，大家可以体验',
    //   duration: 0
    // });
  },
  methods: {
    show () {
      if (!this.isBuild) {
        this.watermark({
          text: '瑶光数据可视化 - Alkaid',
          color: 'rgba(255,0,0,.2)'
        });
      }
      if (window.localStorage.getItem('avue_lock')) {
        return
      }
      this.dialogVisible = true
    },
    submit () {
      if (this.value == '') {
        this.$message.error('验证码不能为空')
        return
      } else if (this.value.toLowerCase() != 'pusdn') {
        this.$message.error('验证码不正确')
        return
      }
      this.dialogVisible = false
      window.localStorage.setItem('avue_lock', true)
    }
  }

}
</script>
