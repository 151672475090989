<!-- 自定义配置 -->
<template>
  <div>
    <el-form-item label="订阅地址">
      <avue-input v-model="main.activeOption.url"
                  placeholder="订阅地址"></avue-input>
    </el-form-item>
    <el-form-item label="订阅主题">
      <avue-input v-model="main.activeOption.topic"
                  placeholder="订阅主题"></avue-input>
    </el-form-item>
    <el-form-item label="账号">
      <avue-input v-model="main.activeOption.username"
                  placeholder="请输入账号"></avue-input>
    </el-form-item>
    <el-form-item label="密码">
      <avue-input v-model="main.activeOption.password"
                  placeholder="请输入密码"></avue-input>
    </el-form-item>
    <el-form-item label="产品ID">
      <avue-input v-model="main.activeOption.productKey"
                  placeholder="请输入产品ID"></avue-input>
    </el-form-item>
    <el-form-item label="设备ID">
      <avue-input v-model="main.activeOption.deviceName"
                  placeholder="请输入设备ID"></avue-input>
    </el-form-item>
    <el-form-item label="参数">
      <avue-array v-model="main.activeOption.params"
                  placeholder="请输入相关参数"></avue-array>
    </el-form-item>
  </div>
</template>
<script>
export default {
  name: 'iot',
  inject: ["main"],
  data () {
    return {

    }
  }
}
</script>

<style>
</style>